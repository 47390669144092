@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho&family=Zen+Old+Mincho&display=swap');
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
html {
  height: 100%;
}

body {
  font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
}

.App,
#root {
  width: 100%;
  height: 100%;
  position: absolute;
}

::-webkit-scrollbar {
  width: 10px;
  height: 13px;
}
::-webkit-scrollbar-track {
  background-color: #282828;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: #8f8f8f;
}
/*スクロールバーのスタイル。特定要素を設定したいときはセレクターを指定*/
/*参考：https://kakechimaru.com/scrollbar_custom/*/
/*この構文はfirefoxには適用されない。*/
/*firefoxに適用可能な新構文についての参考：https://coliss.com/articles/build-websites/operation/css/custom-scrollbars-in-css.html*/

.horizontal-list {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.item {
  /* 横スクロール用 */
  display: inline-block;
  width: 90%;

  /* 見た目調整 */
  height: 400px;
  margin: 16px;
  font-size: 48px;
  background: rgba(255, 0, 0, 0.4);
}

.title-red {
  height: 4.7rem;
  padding: 6px 0;
  margin: 0 0 5px 0;
  background-color: #a94337;
}

.title-red::before {
  content: '';
  display: block;
  position: absolute;
  width: 200%;
  height: calc(100% - 17px);
  border-top: dashed 2px #fff;
  border-bottom: dashed 2px #fff;
  pointer-events: none;
}

.title-red-2 {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: normal;
  line-height: 35px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  padding: 6px 0;
  margin: 0 0 20px 0;
}
.title-red-2 {
  background-color: #a94337;
}

.title-red-2::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% - 10px);
  border-top: dashed 2px #fff;
  border-bottom: dashed 2px #fff;
  top: 5px;
}

.square {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #eee;
  margin-bottom: 15px;
}
.square p {
  margin: 0;
  font-size: 13px;
}

.mov_bg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.mov_bg:hover {
  background: url(./assets/bg_movie.png) right 80% no-repeat;
  background-size: 80px;
}

.bread_bg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.bread_bg:hover {
  background: url(./assets/bg_bread.png) right 80% no-repeat;
  background-size: 80px;
}

.monthly-title-red,
.monthly-title-blue {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
}
.monthly-title-red {
  background: rgba(169, 67, 55, 0.9);
}
.monthly-title-blue {
  background: rgba(169, 67, 55, 0.9);
}
a:hover .movie-title {
  color: #a94337;
}
a:hover .tri::after {
  border-color: transparent #a94337 transparent transparent;
}

a:hover .monthly-title-red,
a:hover .monthly-title-blue {
  background-color: rgba(255, 255, 255, 0.8);
}

.movie-title {
  display: block;
  box-sizing: border-box;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 5em;
  padding: 1em;
  margin: 0;
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.tri::after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #ffffff transparent transparent;
  opacity: 0.8;
}

.tri-red::after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #a94337 transparent transparent;
  opacity: 0.8;
}

.monthly-btn {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 280px;
  height: auto;
  /* font-size: 14px; */
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0;
  color: #a94337;
  text-decoration: none;
  border: solid 5px #a94337;
  padding: 16px 0;
}

a:hover.monthly-btn {
  color: #fff;
  background-color: #a94337;
}
a:hover.monthly-btn.tri-red::after {
  border-color: transparent #ffffff transparent transparent;
}

.curtain-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: url(./assets/curtain.png) bottom center / cover no-repeat;
  z-index: 999;
}

.site-font {
  font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
  letter-spacing: 1px;
  /* font-size: 12px; */
}

/* .filmLike{
  display:block;
  height:15px;
  width:200%;
  padding: 7px 0 0 0;
  margin: 0 0 0px 0;
  background-color: #a94337;
}

.filmLike::before{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  border-top: dashed 2px #fff;
} */

.terms h3 {
  font-weight: bold;
  margin-top: 15px;
}

.terms li {
  margin-top: 10px;
}

.sample3-3::before {
  left: 1px;
  width: 6px;
  height: 4px;
  background: #7a0;
}
.sample3-3::after {
  left: 7px;
  box-sizing: border-box;
  width: 2px;
  height: 2px;
  border: 2px solid transparent;
  border-left: 2px solid #7a0;
}

.triangle-red {
  display: inline-block;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent #a94337;
  z-index: 2;
}

.triangle-white {
  display: inline-block;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent #ffffff;
  margin-left: -9px;
  z-index: 1;
}

.triangle-gray {
  display: inline-block;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent #8f8f8f;
  z-index: 2;
}

.rectangle-start-red {
  display: inline-block;
  background: #a94337;
  height: 30px;
  width: 150px;
  margin-left: -10px;
  text-align: center;
  padding-top: 5px;
  color: white;
  border-radius: 15px 0 0 15px;
}

.rectangle-start-gray {
  display: inline-block;
  background: #8f8f8f;
  height: 30px;
  width: 150px;
  margin-left: -10px;
  text-align: center;
  padding-top: 5px;
  border-radius: 15px 0 0 15px;
}

.rectangle-red {
  display: inline-block;
  background: #a94337;
  height: 30px;
  width: 150px;
  margin-left: -10px;
  text-align: center;
  padding-top: 5px;
  color: white;
}

.rectangle-gray {
  display: inline-block;
  background: #8f8f8f;
  height: 30px;
  width: 150px;
  margin-left: -10px;
  text-align: center;
  padding-top: 5px;
}

.cinema_detail_gradient {
  background: linear-gradient(to top, #a94337, 99%, transparent);
}

.scheduleTable {
  border: solid 1px #000000;
  border-collapse: separate;
  border-spacing: 0px 8px;
}

/*高さアニメーション*/
.height-transition {
  transition: height 0.2s ease-in-out;
}

.height-transition-chargelist {
  transition: height 0.4s linear;
}

.height-transition-checkDuplicatedSwitch {
  overflow: hidden;
  transition: height 0.7s ease-out;
}

.height-transition-ticketBreakdown {
  overflow: hidden;
  transition: height 0.7s ease-out;
}
/*Stripe CardElement*/

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 10px;
  padding: 5px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: undefined;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: undefined;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rat {
  position: absolute;
  display: block;
  bottom: -2px;
  right: 5%;
  width: 60px;
  height: auto;
}

.title-white-red,
.title-white-blue {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: normal;
  line-height: 2em;
  letter-spacing: 0;
  text-align: center;

  padding: 6px 0;
  margin: 0 0 20px 0;
}
.title-white-red {
  color: #a94337;
  border-top: solid 2px #a94337;
  border-bottom: solid 2px #a94337;
}
.title-white-blue {
  color: #a94337;
  border-top: solid 2px #a94337;
  border-bottom: solid 2px #a94337;
}
.title-white-red::before,
.title-white-red::after,
.title-white-blue::before,
.title-white-blue::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
}
.title-white-red::before,
.title-white-red::after {
  border-top: solid 1px #a94337;
}
.title-white-blue::before,
.title-white-blue::after {
  border-top: solid 1px #a94337;
}
.title-white-red::before,
.title-white-blue::before {
  top: 2px;
}
.title-white-red::after,
.title-white-blue::after {
  bottom: 2px;
}

.title-sideb {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 24px;
  line-height: 2em;
  letter-spacing: 0;
  text-align: center;
  color: #a94337;
  padding: 0 2em;
  border-right: solid 1px #a94337;
  border-left: solid 1px #a94337;
}

.iframe-content {
  position: relative;
  width: 100%;
  padding: 85% 0 0 0;
}

@media screen and (min-width: 640px) {
  .iframe-content {
    width: 80%;
    padding: 56.25% 0 0 0;
    margin-bottom: -70px;
  }
}

.iframe-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
}

.ql-container {
  font-size: 16px !important;
  letter-spacing: 0px !important;
  font-family: '游ゴシック体', 'Yu Gothic', 'YuGothic', 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', sans-serif !important;
  color: #a94337 !important;
  height: 72vh !important;
  /*！importantをつけないとstyleが効かないので注意。*/
}
.cinema_detail .ql-container {
  height: 50vh !important;
}

.event_notification_editor .ql-container {
  height: 20vh !important;
}

/*Quillでhrを入れるときのstyle*/
.ql-editor div[data-divider='true'] {
  border-top: 1px solid #8f8f8f;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  width: 90%;
}

/*alignが効かないのでオーバーライドする*/
/*参考：https://github.com/zenoamaro/react-quill/issues/553*/
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}

.ql-align-right img {
  margin-left: auto;
}

.ql-align-center img {
  margin-left: auto;
  margin-right: auto;
}

.ql-align-left img {
  margin-right: auto;
}

/*BlotFormatterのalign変更は動作しないためツールバーを非表示にしている。*/
/*参考：https://github.com/Fandom-OSS/quill-blot-formatter/issues/5*/
.blot-formatter__toolbar-button {
  display: none !important;
}

/*コメントアウトでダブルスラッシュにするとstyleが効かなくなるので注意。*/
